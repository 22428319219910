import React from 'react';
import { SpriteFourStep, SpriteTwoStep } from "../routes/Experimental";

import poke1 from "../images/bulba/001.png"
import poke2 from "../images/bulba/002.png"
import poke3 from "../images/bulba/003.png"
import poke4 from "../images/bulba/004.png"
import poke5 from "../images/bulba/005.png"
import poke6 from "../images/bulba/006.png"
import poke7 from "../images/bulba/007.png"
import poke8 from "../images/bulba/008.png"
import poke9 from "../images/bulba/009.png"
import poke25 from "../images/bulba/025.png"
import poke060 from "../images/bulba/060.png"
import poke061 from "../images/bulba/061.png"
import poke062 from "../images/bulba/062.png"
import poke073 from "../images/bulba/073.png"
import poke082 from "../images/bulba/082.png"
import poke091 from "../images/bulba/091.png"
import poke123 from "../images/bulba/123.png"
import poke143 from "../images/bulba/143.png"
import poke150 from "../images/bulba/150.png"
import poke157 from "../images/bulba/157.png"
import poke197 from "../images/bulba/197.png"
import poke252 from "../images/bulba/252.png"
import poke255 from "../images/bulba/255.png"
import poke258 from "../images/bulba/258.png"
import poke377 from "../images/bulba/377.png"
import poke378 from "../images/bulba/378.png"
import poke379 from "../images/bulba/379.png"
import poke380 from "../images/bulba/380.png"
import poke381 from "../images/bulba/381.png"
import poke382 from "../images/Large/382.png"
import poke383 from "../images/Large/383.png"
import poke384 from "../images/Large/384.png"
import poke385 from "../images/bulba/385.png"
import poke386 from "../images/bulba/386.png"
import poke386_0 from "../images/bulba/386_0.png"
import poke386_1 from "../images/bulba/386_1.png"
import poke386_2 from "../images/bulba/386_2.png"
import poke386_3 from "../images/bulba/386_3.png"

export const PokeRender = (props) => {
  const [count, setCount] = React.useState(0);
  const [count2, setCount2] = React.useState(0);

  const requestRef = React.useRef();
  const previousTimeRef = React.useRef();

  window.addEventListener("keydown", function (e) {
    if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"]
      .indexOf(e.code) > -1) {
      e.preventDefault();
    }
  }, false);

  const animate = time => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;

      // This last mod is the range
      // the decimal is speed of animation
      setCount(prevCount => (prevCount + deltaTime * 0.0035) % 3);
      setCount2(prevCount => (prevCount + deltaTime * 0.001) % 1);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  }

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  });

  let frame = Math.round(count);
  let frame1 = Math.round(count2);
  let frame2 = frame1 === 0 ? 1 : 0;

  let direction = "0" // down
  let inverse_direction = "3" // up
  let directionName = "Down" // front

  if (props.direction === "left key detected.") {
    direction = 1;
    inverse_direction = 2;
    directionName = "Left"
  } else if (props.direction === "right key detected.") {
    direction = 2;
    inverse_direction = 1;
    directionName = "Right"
  } else if (props.direction === "up key detected.") {
    direction = 3;
    inverse_direction = 0;
    directionName = "Up"
  } else {
    direction = 0;
    inverse_direction = 3;
    directionName = "Down"
  }

  const images = require.context('../images/bulba', true);
  const imageList = images.keys().map(image => images(image));

  return (
    <div>
      <br />
      {"Direction: " + directionName}
      <br />
      {"4step: " + frame}
      <br />
      {"2Step: " + frame1}
      <br />

      <br/>
      <br/>
      {/* walking  */}
      {SpriteFourStep(frame, poke1, 0, direction)}
      {SpriteFourStep(frame, poke2, 0, direction)}
      {SpriteFourStep(frame, poke3, 0, direction)}
      <br/>
      <br/>
      {SpriteFourStep(frame, poke4, 0, direction)}
      {SpriteFourStep(frame, poke5, 0, direction)}
      {SpriteFourStep(frame, poke6, 0, direction)}
      <br/>
      {SpriteFourStep(frame, poke7, 0, direction)}
      {SpriteFourStep(frame, poke8, 0, direction)}
      {SpriteFourStep(frame, poke9, 0, direction)}
      <br/>
      <br />
      {SpriteFourStep(frame, poke060, 0, direction)}
      {SpriteFourStep(frame, poke061, 0, direction)}
      {SpriteFourStep(frame, poke062, 0, direction)}
      <br />
      <br />
      {SpriteFourStep(frame, poke25, 0, direction)}
      {SpriteFourStep(frame, poke150, 0, direction)}
      {SpriteFourStep(frame, poke157, 0, direction)}
      <br />
      {SpriteFourStep(frame, poke091, 0, direction)}
      {SpriteFourStep(frame, poke386, 0, direction)}
      {SpriteFourStep(frame, poke143, 0, direction)}
      <br />
      <br />


      <br />
      <br />
      {/* nonwalking  2s */}
      {SpriteTwoStep(frame1, poke123, 0, direction)}
      {SpriteTwoStep(frame2, poke082, 0, direction)}
      {SpriteTwoStep(frame1, poke197, 0, direction)}
      <br />
      <br />
      {SpriteTwoStep(frame1, poke073, 0, direction)}
      {SpriteTwoStep(frame2, poke252, 0, direction)}
      {SpriteTwoStep(frame1, poke255, 0, direction)}
      {SpriteTwoStep(frame2, poke258, 0, direction)}

      <br />
      <br />
      {SpriteTwoStep(frame1, poke377, 0, direction)}
      {SpriteTwoStep(frame2, poke378, 0, direction)}
      {SpriteTwoStep(frame1, poke379, 0, direction)}
      {SpriteTwoStep(frame2, poke380, 0, direction)}
      {SpriteTwoStep(frame1, poke381, 0, direction)}
      {SpriteTwoStep(frame2, poke385, 0, direction)}

      <br/>
      <br/>
      {SpriteTwoStep(frame2, poke386_0, 0, direction)}
      {SpriteTwoStep(frame2, poke386_1, 0, inverse_direction)}
      {SpriteTwoStep(frame2, poke386_2, 0, direction)}
      {SpriteTwoStep(frame2, poke386_3, 0, inverse_direction)}

      <br />
      <br />
      {SpriteTwoStep(frame2, poke383, 0, direction, 2)}
      {SpriteTwoStep(frame1, poke382, 0, direction, 2)}
      {SpriteTwoStep(frame2, poke384, 0, direction, 2)}

      <br />
      <br />
      { imageList.map((image, index) => (
        <>
          { index === 314 ? (<br/>) : ""}
          { index % 14 === 0 ? (<br/>) : ""}
          {
            index === 9 || index === 39 || index === 74 ||
            index === 80 || index === 100 || index === 107 ||
            index === 111 || index === 122 || index === 149 ||
            index === 150 || (index >= 238 && index <= 263) ||
            index === 270 || index === 311 || index === 312 ||
            index === 383 || index === 395 || index === 444 ||
            index === 445 || index === 446 || index === 563 ||
            index === 564 || index === 566 || index === 567 ||
            index > 572
            ?
            <></> :
            SpriteFourStep(frame, image, 0, direction)
          }
        </>
      )) }

    </div>
  )
};